/** @format */

import { useEffect, useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Zoom from '@material-ui/core/Zoom';
import 'react-datepicker/dist/react-datepicker.css';
import { AllButton, OrderListWrapper, useStyles } from './styles';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Tooltip } from '@material-ui/core';
import { useOrderAPI } from './hooks/useOrderAPI';
import LoadIndicator from 'components/common/LoadIndicator/LoadIndicator';
import { getApi } from 'api/axios';
import { useRef } from 'react';
import QueryBuilder from 'components/common/QueryBuilder/QueryBuilder';
import { useSelector } from 'react-redux';
import { useNotificationsApi } from 'pages/SuperAdmin/Notifications/hooks/useNotificationsApi';
import NotificationDialog from 'components/common/NotificationDialog/NotificationDialog';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import WarningIcon from '@mui/icons-material/Warning';
import { amber } from '@mui/material/colors';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { CheckCircle } from '@mui/icons-material';
import { Search } from '@material-ui/icons';

const Orders = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const navigate = useNavigate();
  const { search } = useLocation();

  const [showAll, setShowAll] = useState(false);

  const shopId = useSelector((state) => state.nonPersistedReducers?.shopSliceReducer?.shopId);

  const user = useSelector((state) => state.nonPersistedReducers?.userSliceReducer?.currentUser);

  const { fetchOrders, orders, ordersLength, loading } = useOrderAPI();

  const { fetchAdminNotification } = useNotificationsApi();

  useEffect(() => {
    fetchAdminNotification();
  }, []);

  const getShowAllQueryString = () => {
    let queryParams = new URLSearchParams(search);
    queryParams.set('page', 1);
    queryParams.set('size', ordersLength);
    return queryParams.toString();
  };
  const handleShowAll = () => {
    setShowAll(!showAll);

    navigate(`/shops/${shopId}/administration/orders?${getShowAllQueryString()}`);
  };

  const renderSwitch = (param) => {
    switch (param) {
      case 'NO_INVOICE':
        return (
          <Tooltip title={'Nincs számlázva'}>
            <RemoveCircleIcon sx={{ color: 'grey', fontSize: 30 }} />
          </Tooltip>
        );
      case 'PARTIAL_INVOICE':
        return (
          <Tooltip title={'Részben számlázva'}>
            <WarningIcon sx={{ color: amber[500], fontSize: 30 }} />
          </Tooltip>
        );
      case 'FAILED_INVOICE':
        return (
          <Tooltip title={'Sikertelenül számlázva'}>
            <CancelIcon sx={{ color: 'red', fontSize: 30 }} />
          </Tooltip>
        );
      case 'INVOICED':
        return (
          <Tooltip title={'Sikeresen számlázva'}>
            <CheckCircle sx={{ color: '#7ac142', fontSize: 30 }} />
          </Tooltip>
        );
      default:
        return <div>Hiba</div>;
    }
  };

  const handleStatusTranslate = (status) => {
    if (status === 'PENDING') {
      return 'FÜGGŐBEN';
    }
    if (status === 'RECEIVED') {
      return 'FIZETÉSRE VÁR';
    }
    if (status === 'INITIATED') {
      return 'FIZETÉSRE VÁR';
    }
    if (status === 'CANCELLED') {
      return 'MEGSZAKÍTOTT FIZETÉS';
    }
    if (status === 'CONFIRMED') {
      return 'FIZETÉSRE VÁR';
    }
    if (status === 'PAID') {
      return 'FIZETVE';
    }
    if (status === 'INVOICED') {
      return 'SZÁMLÁZVA';
    }
    if (status === 'SHIPPING') {
      return 'KISZÁLLÍTÁS ALATT';
    }
    if (status === 'DONE') {
      return 'TELJESÍTVE';
    }
    if (status === 'REJECTED') {
      return 'VISSZAUTASÍTVA';
    }
    if (status === 'REJECTED_PAYMENT') {
      return 'SIKERTELEN FIZETÉS';
    }
    if (status === 'REJECTED_INVOICE') {
      return 'SIKERTELEN SZÁMLÁZÁS';
    }
    if (status === 'REJECTED_DELIVERY') {
      return 'SIKERTELEN KÉZBESÍTÉS';
    }
  };

  const handleStatusStyle = (status) => {
    if (status === 'PENDING') {
      return classes?.pending;
    }
    if (status === 'PAID') {
      return classes?.paid;
    }
    if (status === 'INVOICED') {
      return classes?.invoiced;
    }
    if (status === 'SHIPPING') {
      return classes?.shipping;
    }
    if (status === 'DONE') {
      return classes?.done;
    }
    if (status === 'REJECTED') {
      return classes?.rejected;
    }
    if (status === 'REJECTED_PAYMENT') {
      return classes?.rejected;
    }
    if (status === 'REJECTED_INVOICE') {
      return classes?.rejected;
    }
    if (status === 'REJECTED_DELIVERY') {
      return classes?.rejected;
    }
  };

  const handleGetPdf = async () => {
    try {
      const res = await (
        await getApi({ baseUrl: window._env_.REACT_APP_QDAK_ORDER_URL })
      ).get(
        `/admin/shops/${shopId}/orders/export/pdf${
          search && search?.includes('size')
            ? search.split('size=')[0] + 'size=100000&' + search.split('size=')[1]?.split('&')[1]
            : search
        }${search ? '' : '?size=100000&page=1'}`,
        {
          headers: {
            Accept: 'application/pdf',
            'Content-Type': 'application/pdf'
          },
          responseType: 'blob'
        }
      );

      const href = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }));

      const a = linkRef.current;
      a.download = `Rendeles_lista_${new Date().toLocaleDateString('hu-HU')}.pdf`;
      a.href = href;
      a.click();
      a.href = '';
    } catch (e) {
      console.log(e);
    }
  };

  const handleGetCSV = async () => {
    try {
      const res = await (
        await getApi({ baseUrl: window._env_.REACT_APP_QDAK_ORDER_URL })
      ).get(
        `/admin/shops/${shopId}/orders/export/pdf${
          search && search?.includes('size')
            ? search.split('size=')[0] + 'size=100000&' + search.split('size=')[1]?.split('&')[1]
            : search
        }${search ? '' : '?size=100000&page=1'}`,
        {
          headers: {
            Accept: 'application/csv',
            'Content-Type': 'application/csv'
          },
          responseType: 'blob'
        }
      );

      const href = window.URL.createObjectURL(new Blob([res.data], { type: 'application/csv; charset=UTF-8' }));

      const a = linkRef.current;
      a.download = `Rendeles_lista.csv`;
      a.href = href;
      a.click();
      a.href = '';
    } catch (e) {
      console.log(e);
    }
  };

  const routeChange = (id) => {
    let path = `${id}`;
    navigate(path);
  };

  const refresh = () => {
    fetchOrders({ queryString: search });
  };
  const linkRef = useRef(null);

  useEffect(() => {
    refresh();
    let queryParams = new URLSearchParams(search);

    if (queryParams.get('size') !== ordersLength) {
      setShowAll(false);
    }
  }, [search]);

  const path = useLocation().pathname;

  const resetQueryString = () => {
    navigate(path);
  };
  return (
    <Container maxWidth={false} className={classes.root}>
      <CssBaseline />
      <a ref={linkRef} />
      <Breadcrumbs aria-label='breadcrumb' className={classes.breadcrumb}>
        <Typography variant='body1'>Rendelések</Typography>
      </Breadcrumbs>
      <Zoom in={true} timeout={1000}>
        <Container maxWidth={false} className={classes.container}>
          <QueryBuilder
            itemsLength={ordersLength}
            siteType={'order'}
            showAll={showAll}
            reset={resetQueryString}
            refresh={refresh}>
            <table className={classes.headerTop}>
              <tbody>
                <tr width='100%' className={classes.searchBar}>
                  <td
                    className={classes.tableCell}
                    style={{
                      color: 'whitesmoke',
                      textAlign: 'center'
                    }}>
                    <Tooltip title={'Rendelés összesítő lista letöltése a szűrő feltételek szerint'}>
                      <div>
                        <Button
                          variant='outlined'
                          disabled={orders?.length < 1}
                          style={{
                            backgroundColor: theme.palette.primary.backgroundContent,
                            color: theme.palette.primary.main,
                            margin: 0,
                            border: `2px solid ${theme.palette.primary.backgroundPrimary}`
                          }}
                          onClick={() => handleGetPdf(orders)}>
                          <PictureAsPdfIcon />
                          PDF letöltés
                        </Button>
                      </div>
                    </Tooltip>
                  </td>
                  <td
                    className={classes.tableCell}
                    style={{
                      color: 'whitesmoke',
                      textAlign: 'center'
                    }}>
                    <Tooltip title={'Rendelés összesítő lista letöltése a szűrő feltételek szerint'}>
                      <div>
                        <Button
                          variant='outlined'
                          disabled={orders?.length < 1}
                          style={{
                            backgroundColor: theme.palette.primary.backgroundContent,
                            color: theme.palette.primary.main,
                            margin: 0,
                            border: `2px solid ${theme.palette.primary.backgroundPrimary}`
                          }}
                          onClick={() => handleGetCSV()}>
                          <FileDownloadIcon />
                          CSV letöltés
                        </Button>
                      </div>
                    </Tooltip>
                  </td>
                </tr>
              </tbody>
            </table>

            <table className={classes.header}>
              <thead>
                <tr width='100%'>
                  <td className={classes.hideXs} style={{ width: '5%' }}>
                    <Typography className={classes.heading}>#</Typography>
                  </td>
                  <td className={classes.hideXs} style={{ width: '10%' }}>
                    <Typography className={classes.heading}>Azonosító</Typography>
                  </td>
                  <td className={classes.heading}>
                    <Typography className={classes.heading}>Ügyfél neve</Typography>
                  </td>
                  <td className={classes.heading}>
                    <Typography className={classes.heading}>Rendelés dátuma</Typography>
                  </td>
                  <td className={classes.hideXs}>
                    <Typography className={classes.heading}>Kiszállítási cím</Typography>
                  </td>
                  <td className={classes.hideXs}>
                    <Typography className={classes.heading}>Összeg</Typography>
                  </td>
                  <td className={classes.hideXs}>
                    <Typography className={classes.heading}>Számlázás</Typography>
                  </td>
                  <td className={classes.tableCell}>
                    <Typography className={classes.heading}>Státusz</Typography>
                  </td>
                </tr>
              </thead>
            </table>
            <OrderListWrapper>
              <table className={classes.listitemtable}>
                <tbody>
                  {loading ? (
                    <tr>
                      <td>
                        <LoadIndicator color={theme.palette.primary.light} height={800} />
                      </td>
                    </tr>
                  ) : orders?.length > 0 ? (
                    orders?.map((order, idx) => (
                      <tr
                        className={classes.listItem}
                        width='100%'
                        key={order.id}
                        onClick={() => {
                          routeChange(order.id);
                        }}>
                        <td className={classes.hideXs} style={{ width: '5%' }}>
                          <Typography className={classes.tableCell}>{idx + 1}</Typography>
                        </td>
                        <td className={classes.hideXs} style={{ width: '10%' }}>
                          <Typography className={classes.tableCell}>{order?.id}</Typography>
                        </td>
                        <td className={classes.tableCell}>
                          <Typography className={classes.tableCell}>{order?.billingInfo?.customerName}</Typography>
                        </td>
                        <td className={classes.tableCell}>
                          {order?.createdAt && (
                            <Typography className={classes.tableCell}>
                              {new Date(order?.createdAt).toLocaleDateString('hu-HU', {
                                hour: '2-digit',
                                minute: '2-digit',
                                seconds: '2-digit'
                              })}
                            </Typography>
                          )}
                        </td>

                        <td className={classes.hideXs}>
                          <Typography className={classes.tableCell}>{order?.deliveryTarget?.deliveryTarget}</Typography>
                        </td>
                        <td className={classes.hideXs}>
                          <Typography className={classes.tableCell}>{order?.totalPrice?.grossAmount} Ft</Typography>
                        </td>
                        <td className={classes.hideXs}>
                          <Typography className={classes.tableCell} component='div'>
                            {renderSwitch(order?.invoiceState)}
                          </Typography>
                        </td>
                        <td className={classes.tableCell}>
                          <Typography
                            style={{
                              fontFamily: theme.typography.main
                            }}
                            className={handleStatusStyle(order?.status)}>
                            {handleStatusTranslate(order?.status)}
                          </Typography>
                        </td>
                      </tr>
                    ))
                  ) : !user?.ownedShops?.find((shop) => shop.id == shopId) &&
                    !user?.maintainedShops?.find((shop) => shop.shop.id === shopId)?.scopes?.includes('VIEW_ORDERS') ? (
                    <tr
                      style={{
                        margin: '0.5rem',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                      }}>
                      <td>
                        <Typography
                          variant='h5'
                          style={{
                            color: theme?.palette?.primary?.light
                          }}>
                          A rendelések megtekintéséhez rendelkeznie kell a <b>VIEW_ORDERS</b> jogosultsággal.
                        </Typography>
                      </td>
                    </tr>
                  ) : (
                    <tr
                      style={{
                        margin: '0.5rem',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                      }}>
                      <td>
                        <Typography
                          variant='h5'
                          style={{
                            color: theme?.palette?.primary?.light
                          }}>
                          Nem érkezett még rendelés.
                        </Typography>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </OrderListWrapper>
          </QueryBuilder>

          <AllButton
            className={classes.allBtn}
            onClick={() => {
              handleShowAll();
            }}>
            {showAll ? 'Kevesebb rendelés' : 'Összes rendelés'}
          </AllButton>
        </Container>
      </Zoom>
      <NotificationDialog />
    </Container>
  );
};

export default Orders;
